.btn-group {
    .btn-light {
        padding:.675rem .5rem;
        font-size: 14px;
        &:not(.disabled).active {
            background-color: rgba($dark-eve-color-dark-600, 0.8);
            border-color: rgba($dark-eve-color-dark-600, 0.8);
            color: $grey-eve-color-grey-100
        }
        .icon {
            font-size: 1rem;
        }
    }
}
