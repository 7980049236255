@import './variables';

@mixin scrollbar {
        /* width */
        &::-webkit-scrollbar {
           width: 6px;
           height: 6px;
       }
       
       /* Track */
       &::-webkit-scrollbar-track {
           background: $dark-100; 
           border-radius: 4px;
       }
       
       /* Handle */
       &::-webkit-scrollbar-thumb {
           background: $grey-200; 
           border-radius: 4px;
       }
       
       /* Handle on hover */
       &::-webkit-scrollbar-thumb:hover {
           background: $grey-300; 
       }
}
