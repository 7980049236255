.sort{
    .icon{
        color: var(--primary);
        float: right;
        cursor: pointer;
    }
}

.table-container {
    overflow-y: hidden;
    overflow-x: auto;

    @include scrollbar;
}


.table{
    &-custom{
        border-spacing: 0;
        border-collapse: separate;

        @extend .table,.table-bordered,.table-striped;
        border: 0;;
        margin-bottom: 0;
        thead{
            th{
                white-space: nowrap;
                font-size: 13px;
                font-weight: 600;
                vertical-align: top;
                @media (min-width: 2880px) {
                    font-size: 36px;
                    padding: 20px;
                }
            }
        }
        tbody{
            td{
                vertical-align: middle;
                font-size: 13px;
                @media (min-width: 2880px) {
                    font-size: 36px;
                    padding: 20px;
                }
            }
        }
        th{
            background-color: $th-background;
            vertical-align: top;
            &.sort{
                >div{
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                }
            }
        }
        td.data-name{
            text-align: left;
        }
        td.data-value{
            text-align: right;
        }
        tr{
            &:first-child{
                th:first-child{
                    border-top-left-radius: 6px;
                }
                th:last-child {
                    border-top-right-radius: 6px;
                  }
            }
            &:last-child {
                td:first-child {
                    border-bottom-left-radius: 6px;
                  }
                td:last-child {       
                    border-bottom-right-radius: 6px;
                  }
            }
        }

        
    }

    &-conversion {
        border-spacing: 0;
        border-collapse: separate;

        @extend .table,.table-bordered;
        border: 0;;
        margin-bottom: 0;
        thead{
            th{
                white-space: nowrap;
                font-size: 13px;
                font-weight: 600;
                vertical-align: top;
                background-color: #FAFAFA;
                @media (min-width: 2880px) {
                    font-size: 36px;
                    padding: 20px;
                }
            }
        }
        tbody{
            td{
                vertical-align: middle;
                font-size: 13px;
                @media (min-width: 2880px) {
                    font-size: 36px;
                    padding: 20px;
                }
            }

            tr{
                &:nth-of-type(even) {
                    background-color: #FAFAFA !important;
                }
            }
        }
        th{
            background-color: $th-background;
            vertical-align: top;
            &.sort{
                >div{
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                }
            }
        }
        td.data-name{
            text-align: left;
        }
        td.data-value{
            text-align: right;
        }
        tr{
            &:first-child{
                th:first-child{
                    border-top-left-radius: 6px;
                }
                th:last-child {
                    border-top-right-radius: 6px;
                  }
            }
            &:last-child {
                td:first-child {
                    border-bottom-left-radius: 6px;
                  }
                td:last-child {       
                    border-bottom-right-radius: 6px;
                  }
            }
        }

        .head-footer{
                margin-top: 20px;
                display: block !important;
        }

        th:first-of-type .head-footer {
            margin-top: 5px;
        }
    }
    
  
}

.insideSalesWorkload{
    width: 25%;
}
.insideSalesWorkload_table{
    margin-bottom: 0px !important;
    border-bottom: 0px !important;
    tr{
        th {
            width: 25%;
        }

    }
}
.dailyWeeklyBrokerReport_0{
    .material-icons{
        display: none !important;
    } 
}

.footer_weeklyData, .footer_leadOpportunityReport{
    display: none !important;
}

.report-table-title{
    margin-top: 20px;
    font-size:14px;
    @media (min-width: 2880px) {
        font-size: 36px;
    }
}

.form-control{
    font-size: 13px;
    height: 40px;
    @media (min-width: 2880px) {
        font-size: 36px;
    }
}
.footer{
    font-size: 14px;
    @media (min-width: 2880px) {
        font-size: 36px;
    }
}

.data-verification {
    &-sn {
        min-width: 150px;
        width: 10vw;
    }
    &-actions {
        min-width: 150px;
        width: 10vw;
    }
}

