.title{
    font-weight: 600;
   @extend .font-weight-bold;
}
.box{
    &-widget{
        display: flex;
        align-items: center;

    }
    &-title{
        @extend .small,.mb-1;
    }
    &-value{
        font-size: 1.5rem;
        font-weight: bold;
    }
}
.user{
    &-info{
        font-size: 0.875rem;
        &+.avatar{
            padding: 3px;
        }
    }

}
.footer{
    border-top:1px solid black !important;
}
.card-header-border{
    border-bottom: 0px solid  !important;
    margin-top:40px !important;
}

// positions
.p-relative {
    position: relative !important;
}

.p-absolute {
    position: absolute !important
}

// z-indices
.z-2 {
    z-index: 2 !important;
}
  
// width and height
.w-fit {
    width: fit-content !important;
}
  
.h-fit {
    height: fit-content !important;
}

// fonts
.font-600 {
    font-weight: 600 !important;
}

.blur-1 {
    filter: blur(4px) !important;
}
.blur-2 {
    filter: blur(8px) !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.whitespace-nowrap {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rotate-180 {
    transform: rotate(180deg);
}
