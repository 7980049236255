.tooltip{
    max-width: 350px;
    -webkit-perspective: 700px;
    perspective: 700px;
    z-index: 9999;
    outline: 0;
    transition-timing-function: cubic-bezier(.165, .84, .44, 1);
    pointer-events: none;
    line-height: 1.4;
    opacity: 0;
    background-color: $dark-600;
    padding: 0.35rem 0.5rem;
    border-radius: 0.24rem;
    color: white;
    transform: scale(0) translateX(-1rem);
    white-space: nowrap;
    transition: all 100ms cubic-bezier(.46, .1, .52, .98);

   

  

    &::before{
        position: absolute;
        content: "";
        border-right: 7px solid $dark-600;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        left: -7px;
        margin: 3px 0;
       
   
        transform-origin: 66.66666666% 50%

    
    }
}
.has-tooltip{
    &:hover{
        .tooltip{
            opacity: 1;
            transform: scale(1) translateX(3.5rem);
            transition: all 100ms cubic-bezier(.46, .1, .52, .98);
        }
    }
}
