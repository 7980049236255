.loginBg {
    height: 100vh;
    background-color: linear-gradient(to right, $gradient-1, $gradient-2);
    padding-top: 10vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.loginWrp {
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    max-height: 300px;
}
.hlogin {
    align-items: center;
    max-width: 500px;
    width: 100%;
    background: white;
    padding: 30px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-direction: column;
    max-height: 392px;
}
.hlogin .gi {
    width: 22px;
}
.hlogin button span {
    display: inline-block;
    background: white;
    padding: 15px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.hlogin button div {
    flex-grow: 1;
    font-size: 18px;
    font-weight: 600;
}
.hlogin .titlelogo {
    max-width: 250px;
    margin-top: 30px;
}
.hlogin .googleBtn {
    padding: 0px;
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 300px;
}
