.sidebar {
    padding: 0 0.5rem;
    height: calc(100% - 92px);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    .nav-link {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 1.0125rem;
        padding: 0.75rem 1.25rem;
        line-height: 1.2;
        color: $dark;
        font-size: 14px;

        .icon {
            width: 20px;
            height: 18.57px;
            @extend .mr-2;
            path {
                fill: $body-color;
            }
        }

        &.active, &[aria-expanded="true"] {
            font-weight: 500;
            line-height: 20px;
            color: var(--primary);
            
            .icon {
                path {
                    fill: var(--primary);
                }
            }
            &:before {
                opacity: 1;
                transition: all 100ms ease-in;
            }
        }
        &:hover {
            border-color: none;
            background-color: rgba($primary,0.05);
            color: var(--primary);
            .icon {
                path {
                    fill: var(--primary);
                }
            }
        }
        &-wrapper {
            display: flex;
            align-items: center;
            position: relative;
            &:hover {
                border-color: none;
                background-color: rgba($primary,0.05);
                color: var(--primary);
                .icon {
                    path {
                            fill: var(--primary);
                    }
                }
            }
            &.active, &[aria-expanded="true"] {
                font-weight: 500;
                line-height: 20px;
                color: var(--primary);
                .icon {
                    path {
                        fill: var(--primary);
                    }
                }
                &:before {
                    opacity: 1;
                    transition: all 100ms ease-in;
                }
            }
            .nav-link {
                padding-right: 0.5rem;
                color: inherit;
                &::before {
                    content: unset;
                }
            }
        }
    }

    .nav-item {
        margin-bottom: 0.5rem;
    }

    .nav-title {
        text-transform: uppercase;
        line-height: 40px;
        font-size: 12px;
        color: $grey-500;
        font-weight: 600;
        margin: 10px 20px;
    }

    .nav-btn-container {
        padding: 20px;
        position: fixed;
        width: 254px;
        left: 0;
        bottom: 0;
        background-color: $grey-100;
        .nav-btn {
            height: 50px;
            animation: nav-btn-container 500ms ease-in-out;
        }
        .icon {
            &-active {
                color: var(--primary);
            }
        }
    }

    @include scrollbar;
}

@keyframes nav-btn-container {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}