$sidebar-footer-height: 90px;

.navbar{
    padding: 0.5rem 1.5rem;
    &-brand{
        padding: 1.25rem 1.65rem;
    }
    &-vertical{
        left: 0;
        height: 100vh;
        position: fixed;
        display: inline-block;
        padding: 0;
        z-index: $zindex-fixed ;
        top: 0;
        max-width: 15.875rem;
        width: 100%;
        flex-direction: column;
        margin: 0 -1rem;
        background-color: $grey-100;
        box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.08);
        transition: all 200ms ease-in-out;
        margin: 0;

        @include media-breakpoint-up(xl) { 
            max-width: 15.875rem;
            top: 0;
            height: 100vh;
            margin: 0;
        }

        &+.page-content {
            @include media-breakpoint-up(xl) {
                margin-left: 15.875rem;
            }
        }

        .tooltip {
            display: none;
        }

        .nav-item {
            display: block;
        }

        .nav-item {
            span {
                white-space: nowrap;
                display: block;
            }
        }

        &--min {
                transition: all 200ms ease-in-out;
                max-width: 3.875rem;
                &.hover-state {
                    max-width: 15.875rem;
                    width: 100%;
                    .logo-content {
                        display: block;
                    }
                    .tooltip {
                        display: none;
                    }
                    a.nav-link {
                  
                        padding: 0.75rem 1.25rem;
                        align-items: center;
                       
                        span{
                            display: block;
                            opacity:1;
                            transition:0.3 opacity ease;
                        }
                        svg.icon{
                            margin-right: 0.5rem !important;
                            margin-left: unset;
                        }
                    }
                    .submenu.collapse.show {
                        display: block;
                        transition: all 200ms ease-in-out;
                    }
                    .nav-link-wrapper {
                        .nav-link {
                            margin: unset;
                        }
                        .submenu-toggler {
                            display: flex;
                        }
                    }
                }
                &+.page-content{
                    margin-left:3.875rem;
                }
                a.nav-link{
                    padding: 0.75rem 0.5rem;
                    align-items: center;
                    span{
                        display: none;
                        opacity:0;
                        transition:0.3 opacity ease;
                    }
                    svg.icon{
                        margin-right: auto !important;
                        margin-left: auto;
                    }
                }
                .logo-content{
                    display: none;
                }
                .navbar-brand{
                    padding: 1.25rem 0.65rem;
                }
                .tooltip{
                    display: block;
                }
                .submenu.collapse.show{
                    display: none;
                    transition: all 200ms ease-in-out;
                }
                .nav-link-wrapper{
                    .nav-link{
                        margin: auto
                    }
                    .submenu-toggler{
                        display: none;
                    }
                }
            }
    }
    &-nav{
        .nav-link{
            @extend .px-2;
            &:hover{
                background-color: rgba($black,0.05);
                border-radius: 50px;
            }
            span{
                white-space: nowrap;
                display: block;
                opacity:1;
                transition:0.3 opacity ease;
            }
        }
    }
}
.nav{
    &-item{
        .icon{
            fill:$dark;
        }
    }
}
.navigation{
    margin-bottom: $sidebar-footer-height;
    &-toggler{
        position: absolute;
        background-color: var(--white);
        top: 57px;
        right: -16px;

        @extend .shadow-sm,.rounded-pill,.d-flex,.align-items-center,.justify-content-center;

        width: 32px;
        height: 32px;
        z-index: $zindex-fixed;
    }
}
.submenu {
    list-style: none;
    margin: 8px 0 !important;

    &-level-2 {
        padding-left: 20px;
    }
    
    .submenu-link{
        padding: 0.75rem 0.5rem;
        position: relative;
        font-size: 14px;
        &:hover, &:active, &.active{
            background-color: transparent;
            color: $primary;
        }
        &:hover + .submenu-toggler {
            .icon {
                fill: $primary;
            }
        }
        &.active + .submenu-toggler {
            .icon {
                fill: $primary;
            }
        }
        &:before{
            content: '';
            position: absolute;
            left: -12px;
            top: 0;
            background-color: $primary-700;
            height: 100%;
            width: 2px;
            opacity: 0.2;
        }
        &.active{
            &::before{
                opacity: 1;
            }
        }
    }
    &-toggler{
        color: inherit;
        cursor: pointer;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(90deg);
        transition: 0.3s transform ease;

        &[aria-expanded="true"] {
            transform: rotate(270deg);
        }
      &:hover{
        text-decoration: none;
        background-color: rgba($primary, 0.25);
        border-radius: 50%;

        .icon {
            fill: $primary;
        }
      }
    }
}