$graph-grey: #aaaaaa;
$graph-red: #ea4335;

$grey-1: #aaaaaa;
$grey-2: #bbbbbb;

// tooltip
$tooltip-bg: #DAF7F9;

// table
$th-background: #F2FBFF;

// oauth
$gradient-1: #b2b6b9;
$gradient-2: #b6e2ff;

// datepicker
$datepicker-blue: #239BCF;
$inrange: #D5F0FC;
$compare-inrange: #FEE5D5;
$compare-point: #E06C21;
$both-inrange: #D5F3D7;

// snackbar
$snackbar-red:#e5383b;

// page
$page-background: #E5E5E5;
$page-body-background: #F5F8FB;

// multisearch
$tag-background: #e1f3fb;
$tag-text: #2D7A9C;
$tag-icon-hover: #aee0f5;

// dropdown
$dropdown-li-hover: #dddedf;

// file upload
$file-upload-text: #bdbdbd;
$file-upload-border: #2196f3;

$pie-1: #4361EE;
$pie-2: #FFB703;
$pie-3: #BC6C25;
$pie-4: #E63946;
$pie-5: #023047;
$pie-6: #2A9D8F;
$pie-7: #00F5D4;
$pie-8: #F15BB5;
$pie-9: #9EF01A;
$pie-10: #9B2226;
$pie-11: #006400;

// sankey
$sankey-link: #C2F0FF;

// logo
$logo-grey: #C4C4C4;

// Heatmap
$heat-1: #F2FCFC;
$heat-2: #BDF1F6;
$heat-3: #8FBAF3;
$heat-4: #0245A3;

// line chart
$line-blue: #4285f4;
$line-orange: #FC8B42;
$line-yellow: #E6A523;
$line-green: #62C465;
$line-red: #ea4335;

// pipeline reports
$pipeline-blue: #4472C4;
$pipeline-orange: #ED7D31;
$pipeline-grey: #A5A5A5;

// people and culture
$staff-blue: #4285F4;
$staff-orange: #FF9900;
$staff-dark: #666666;
$staff-light: #D0E0E3;
$turnover-yellow: #FBBC04;
$turnover-green: #34A853;

// antd chart colors
$antd-blue-1: #6395FA;
$antd-blue-2: #6E61FA;
$antd-blue-3: #D0DFFD;

$antd-yellow-1: #F7C122;
$antd-yellow-2: #F4E7BD;

$antd-orange-1: #FF9D4F;
$antd-orange-2: #FFE2CA;

$antd-green-1: #2A9A99;
$antd-green-2: #63DAAB;
$antd-green-3: #D0F4E6;

$antd-grey-1: #657798;
$antd-grey-2: #D1D6E0;

$antd-teal-1: #75CBED;
$antd-teal-2: #D5EFFA;

$antd-purple-1: #9A67BD;
$antd-purple-2: #E0D2EB;

$antd-pink-1: #FF9EC6;
$antd-pink-2: #FFE2EE;


// Activity Score Colors
$leads: $line-blue;
$calls-count: $line-red;
$email-count: #FBBC04;
$saf-sent: #34A853;
$saf-received: #FF6D01;
$saf-submission: #46BDC6;


// HLE brand colors
$red-700: #AB4747;
$red-600: #B96363;

$green-700: #46B948;
$green-600: #62C465;

$dark-900: #000000;
$dark-800: #121212;
$dark-700: #242424;
$dark-600: #494949;
$dark-500: #6d6d6d;
$dark-400: #929292;
$dark-300: #b6b6b6;
$dark-200: #dbdbdb;
$dark-100: #f0f0f0;

$grey-900: #141414;
$grey-800: #2d2d2d;
$grey-700: #4a4a4a;
$grey-600: #686868;
$grey-500: #858585;
$grey-400: #a2a2a2;
$grey-300: #bfbfbf;
$grey-200: #dddddd;
$grey-100: #f9f9f9;

$primary-100: #f2fbff;
$primary-200: #D5F0FC;
$primary-300: #B8E5F8;
$primary-500: #7ECEF1;
$primary-700: #44B8EA;
$primary-800: #27ADE7;

