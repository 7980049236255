
.page{
    &-content{
        background-color: $page-background;
    }
    &-header{
        @extend .border-bottom;
        .navbar-light{
            background-color: $dark-100;
        }
    }
    &-body{
        background-color: $page-body-background;
        padding: 1.25rem 1rem;
    }
}
.main{
    background-color: $page-background;
}


.content{
    &-header{
@extend .mb-3;
        border-bottom: 1px solid $grey-200;
    }
}
.icon{
    &-holder{
        height: 52px;
        width: 52px;
        display: flex;
        align-items: center;
        justify-self: center;
        color: var(--white);
        @include border-radius(12px);
        padding: 1rem;
        background: linear-gradient(142.1deg, $primary-800 -14.78%, $primary-700 45.59%, $primary-500 114.6%);
    }
    &.icon-sm{
        font-size: 12px;
    }
    &.icon-md{
        font-size: 16px;
    }
}

.rotate-icon{
    transform: rotate(180deg);  
}

.content-header {
    h6{
        font-size: 16px ;
    }
}
.overview-graph {
    font-size: 14px;
    &-bold {
        font-size: 16px;
        font-weight: 600;
    }
}

.Toastify{
    font-size: 14px;
}
