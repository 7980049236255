//resizing html font size to make 14px base font
// html { font-size: 87.5%; }

//now 1rem=14px


$black: rgba(0,0,0,0);
$dark : #494949 !default;
$body-color :#242424 !default;
$border-color:#DBDBDB !default;
$hr-border-color: $border-color;

$font-family-base: 'Open Sans', sans-serif !default;
$primary: #44B8EA !default;
$success: #46B948 !default;
$danger: #AB4747 !default;
$gray-100: #F0F0F0 !default;

$dark-eve-color-dark-600: rgba(73, 73, 73, 1);
$grey-eve-color-grey-100: rgba(249, 249, 249, 1);
$primary-eve-color-primary-200: rgba(213, 240, 252, 1);

$navbar-light-brand-color: #f0f0f0;

//grid
$grid-gutter-width: 16px !default;

//card
$card-border-color: $gray-100;
$card-spacer-y: .625rem !default;
$card-spacer-x: 1.15rem !default;

//table
$table-cell-padding: .5rem !default;
$table-border-width: 1px;
$table-border-color: $gray-100;
$table-striped-order: odd;
$table-accent-bg: #FAFAFA;

//dropdown
$dropdown-box-shadow: 0 0rem 6px rgba($black, .09) !default;
