.pagination {
    font-size: 14px;
    margin-top: 1rem;

    &-note {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .nav-btn {
        height: 35px;
        width: 40px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            font-size: 12px;

            &-disabled {
                color: $dark-400;
            }
        }
    }

    .goto-input {
        height: 35px;
        width: 80px;

        @media (max-width: 842px) {
            width: 40px;
        }
    }

    .total {
        margin-right: 48px;
    }

    .page-rows-selection {
        margin-right: 48px;

        label {
            margin: 0;
            width: fit-content;
            white-space: nowrap;
            margin-right: 8px;
        }

        select {
            height: 35px;
        }
    }
}