.card {
    @extend .h-100;
    @include border-radius(12px);
    border: solid 1px $dark-100;
    position: relative;

    &-header {
        background-color: transparent;
        border-color: $grey-200;
    }

    &-title {
        display: flex;
        align-items: center;
        font-size: 14px;

        &-bold {
            font-size: 18px;
            font-weight: 600;
            position: relative;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    .segment-select {
        display: flex;
        align-self: flex-start;
        cursor: pointer;
        @extend .border, .btn-light, .border, .rounded;
        background-color: $dark-100;
        &:hover {
            background-color: $grey-200;
        }
        padding: 0.125rem 0.5rem;

        .btn {
            font-size: 14px;
            padding:5px;
        }
    }

    &-actions {
        .filter-range {
            display: flex;
            align-self: flex-start;
            cursor: pointer;
            @extend .border, .btn-light, .border, .rounded;
            background-color: $dark-100;
            &:hover {
                background-color: $grey-200;
            }
            padding: 0.375rem 0.5rem;

            .btn {
                font-size: 14px;
                padding:5px;
            }
        }
    }
    .lender-title {
        font-size: 14px;
        text-align: center;
    }
}
.cardWrapper {
    margin: auto 32%;
    font-size: 13px;
   .text {
        text-align: center;
        padding-top: 10px;
        color: black;
        i {
            font-size: 14px;
        }
    } 
}

.cardBody {
    background-color: $grey-100 !important;
    border: 2px dotted $grey-200;
}

.cardList {
    background-color: $grey-100 !important;
    border: 2px solid $grey-200;
    padding: 0;
    margin-top: 10px;
   
}

input,.text {
    text-align: center;
    padding-top: 10px;
}

.uploadText {
    margin-top: 20px;
    font-size: 14px;
}

.cardListBody {
    display: flex;
    color: black;
    justify-content: space-between;
   
}

.cardBody {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.retry-upload {
    cursor: pointer;
}

.fileName {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.file-image {
    margin-bottom: 10px;
}
