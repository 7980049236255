.btn {
    &-primary {
        color: var(--white);
    }

    &-secondary {
        @extend .btn-primary;
        background-color: var(--primary);
        color: var(--white);
        border-color: $primary;
        &:hover, &:focus {
            border-color: $primary;
            background-color: darken($primary, 10);
            color: var(--white);
        }
    }
    &-default {
        &:focus {
            border-color: transparent;
            outline: none;
            box-shadow: none;
        }
    }
    &-light {
        border-color: $dark-300;
        color: $dark-400;
    }
    &_has-icon {
        display: flex;
        align-items: center;
    }
    &-action {
        color: $dark-600;
        border-radius: 0;        
        border-left: 1px solid $grey-200;

        &:hover {
            background-color: rgba($dark,0.1);
        }
    }
    &-more {
        display: flex;
        align-items: center;
        color: $grey-400;
        padding: 0;
        align-self: stretch;
        &:hover{
            background-color: rgba($dark,0.05);
        }
    }

}

.chart-table-toggle {
    height: 47px;
}
