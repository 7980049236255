@import '../../abstracts/variables';

.container {
    white-space: nowrap;
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 10;

    .iconBtn {
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        transition: all 0.2s;

        &-close {
            background-color: white;

            &:hover {
                background-color: $grey-100;
            }
        }
    }

    .listContainer {
        position: absolute;
        bottom: 60px;
        right: 0;
        animation: toggleMenuList 0.2s ease-out;
        transform-origin: 100% 100%;

        .listTitle {
            color: white;
            padding: 12px 16px;
            text-align: center;
        }

        .listItems {
            padding: 12px;

            .item {
                color: $dark-600;
                display: flex;
                align-items: center;
                padding: 8px 12px;
                border-radius: 4px;

                span {
                    margin-left: 2px;
                }
                &:hover {
                    background-color: $grey-100;
                    text-decoration: none;
                    color: $primary-800;   
                    .bullet {
                        background-color: $primary-800;
                    }
                }
            }
        }
    }
}

@keyframes toggleMenuList {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}