@import './scss/abstracts/mixins';
@import './scss/abstracts/variables';

html {
  scroll-behavior: smooth;
}

.card-right-wrapper{
  padding: 0px 8px;
  display: flex;
  flex: 1;
}
.card-right-wrapper > select{
  margin: 0px 5px 0px 5px;
}
.loader{
  opacity: .6;
  z-index: 1;
}
.loading-text{
  display: flex;
  justify-content: center;
}
.tooltip-wrapper{
  padding: 8px 8px 5px 8px;
}
.tooltip-wrapper-li {
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}
.tooltip-title{
  margin-bottom: 10px;
}
.tooltip-point{
  height: 8px;
  width: 8px;
  background-color: $grey-1;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
}
.dropdown{
  position: relative;
}
.dropdown-ul{
  position: absolute;
  top: 90%;
  width: 100%;
  background: white;
  border: 1px solid $grey-1;
  cursor: pointer;
  z-index: 1;
  padding: 6px 4px;
  list-style-type: none;

  @include scrollbar;
}
.dropdown-li{
  font-size: 14px;
  padding: 4px 6px;
  border-radius: 2px;
  text-transform: capitalize;
  text-align: left;
}

.dropdown-li:hover {
  background: $dropdown-li-hover;
}

.tooltip-wrapper .tooltip-title{
  font-size: 1rem;
}

.tooltip-wrapper ul {
  padding: 0;
}

.__react_component_tooltip.pulse-tooltip{
  max-width: 400px;
  padding: 0.5rem 0.8rem;
  line-height: 1.2rem;
  text-align: justify;
  &-bold {
    font-weight: 500;
    font-size: 16px;
  }
}

.MuiSvgIcon-root{
  font-size: 1rem !important;
  margin-bottom: 2px;;
}

/* Lead Conversion Multi-Search Input */
.card-right-wrapper {
	.multi-search-wrapper {
		width: 400px;

		>div {
			flex-wrap: nowrap;
		}

		.border input {
			width: 100% !important;
		}

		input {
			font-size: 11px;

			+div {
				font-size: 11px;
			}
		}
	}
}

/* Lead Conversion Header wrapper*/
@media (max-width: 1025px) {
	[data-testid="wrapper"] {
		overflow: auto;
	}
}

/* Lead Conversion lead type Radio Button*/
.custom-radio-button-group {
	display: flex;
	border: 1px solid #d7d7d7;
	border-radius: 4px;
	overflow: hidden;

	.custom-radio-button {
		flex: 1;
		text-align: center;
		padding: 10px 12px;
		cursor: pointer;
		transition: background-color 0.3s ease, color 0.3s ease;

		@media (max-width: 1174px) {
			padding: 0px 10px;
		}

		&.selected {
			background-color: #27ade7;
			color: #fff;

			&:hover {
				background-color: #27ade7;
			}
		}

		&.unselected {
			background-color: #fff;
			color: #000;
			border-color: #d7d7d7;

			&:hover {
				background-color: #eee;
			}
		}

		&:active,
		&:focus {
			background: #27ade7 !important;
			border-color: #27ade7 !important;
			box-shadow: none !important;
			color: #fff !important;
		}

	}
}