.form{
    &-label{
        color: $gray-500;
        &-sm{
            font-size: small;
            @extend .mb-1;
        }
    }
}
.has-search {
    .form-control {
        padding-left: 2.375rem;
        height: 40px;
    }
    .icon {
        position: absolute;
        z-index: 2;
        display: block;
        width: 2.375rem;
        height: 2.375rem;
        line-height: 2.375rem;
        text-align: center;
        pointer-events: none;
        color: $grey-400;
    }
}

.form {
    &-group {
        &--has-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            &.icon--date-range {
                 &:after {
                     content: 'date_range';
                     font-family: 'Material Icons';
                     font-weight: normal;
                     font-style: normal;
                     line-height: 1;
                     letter-spacing: normal;
                     text-transform: none;
                     display: inline-block;
                     white-space: nowrap;
                     word-wrap: normal;
                     direction: ltr;
                     font-feature-settings: 'liga';
                     -webkit-font-feature-settings: 'liga';
                     -webkit-font-smoothing: antialiased;
                     @extend .ml-2;
                 }
                 .form-control {
                    height: 40px;
                    color: inherit;
                    font-size: inherit;
                    border: 0;
                    box-shadow: 0;
                    outline: 0;
                    cursor: pointer;
                    background-color: transparent;
                    padding: 0;                                      
                    pointer-events: none;
                 }
            }
        }
    }
}

.form-input {
    border: solid 1px $dark-200;
    border-radius: 4px;
    text-align: left;
    outline: none !important;
    padding: 12px;
    font-size: 1rem;
    line-height: 1rem;
    width: 100%;
    background-color: $grey-100;
    color: $dark-700;

    &:disabled {
        background-color: $dark-100;
    }
    &:focus {
      background-color: white;  
      border-color: $grey-300;
    }
    &::placeholder {
        padding-left: 2px;
    }
}

select {
    cursor: pointer;
    
    &:hover {
        background-color: $grey-200;
    }
}
